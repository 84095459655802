<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  labelKeyAdd="dealing-backoffice-info"
  :isLoading="isLoading"
  :items="items"
  :querySearch="getData"
  isHiddenTableCount
  isHiddenPageCount
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDealing',
  data () {
    return {
      headers: [
        { value: 'name_ukr', text: this.$t('courseUkr'), align: 'center', sortable: false },
        { value: 'name_eng', text: this.$t('courseEng'), align: 'center', sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'dealing-backoffice-info', params: { ...this.$route.params, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.dealing,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getListDealing']),
    async getData (params) {
      this.getListDealing({ ...this.$route.params, params })
    }

  }
}
</script>
